<template>
    <div>
        <media-library-renderless
            ref="mediaLibraryRenderless"
            :initial-value="initialValue"
            :validation-errors="validationErrors"
            :route-prefix="routePrefix"
            :validation-rules="validationRules"
            :translations="translations"
            :before-upload="beforeUpload"
            :after-upload="afterUpload"
            :name="name"
            :max-items="maxItems"
            :max-size-for-preview-in-bytes="maxSizeForPreviewInBytes"
            :vapor="vapor"
            :vapor-signed-storage-url="vaporSignedStorageUrl"
            :upload-domain="uploadDomain"
            :with-credentials="withCredentials"
            :headers="headers"
            @changed="$emit('change', $event)"
            @is-ready-to-submit-change="$emit('is-ready-to-submit-change', $event)"
            @has-uploads-in-progress-change="$emit('has-uploads-in-progress-change', $event)"
        >
            <div
                slot-scope="{
                    state,
                    removeMedia,
                    getCustomPropertyInputProps,
                    getCustomPropertyInputListeners,
                    getCustomPropertyInputErrors,
                    getNameInputProps,
                    getNameInputListeners,
                    getNameInputErrors,
                    getDropZoneProps,
                    getDropZoneListeners,
                    getFileInputProps,
                    getFileInputListeners,
                    getErrors,
                    clearObjectErrors,
                    clearInvalidMedia,
                }"
            >
                <icons />

                <div
                    :class="`media-library media-library-multiple ${state.media?.length == 0 ? 'media-library-empty' : 'media-library-filled'
                    } ${sortable && 'media-library-sortable'}`"
                >
                    <list-errors
                        :invalid-media="state.invalidMedia"
                        :top-level-errors="validationErrors[name]"
                        @cleared="clearInvalidMedia()"
                    />

                    <div
                        v-show="state.media && state.media.length"
                        v-dragula="sortable ? state.media : undefined"
                        class="media-library-items"
                        :bag="sortable ? dragulaBagName : undefined"
                    >
                        <div
                            v-for="object in state.media"
                            :key="object.attributes.uuid"
                            class="media-library-item media-library-item-row"
                            :data-media-library-uuid="object.attributes.uuid"
                        >
                            <div
                                v-if="sortable"
                                class="dragula-handle media-library-row-drag"
                            >
                                <icon
                                    v-if="state.media.length"
                                    icon="drag"
                                />
                            </div>

                            <MediaLibraryThumb
                                :upload-info="object.upload"
                                :validation-rules="validationRules"
                                :img-props="{
                                    src: object.attributes.medium || object.attributes.preview_url || object.client_preview,
                                    alt: object.attributes.name,
                                    extension: object.attributes.name ? object.attributes.name.split('.').pop() : '',
                                    size: object.attributes.size,
                                }"
                                @open="openPopup(object)"
                            />

                            <item-errors
                                v-if="getErrors(object).length"
                                :object-errors="getErrors(object)"
                                @back="clearObjectErrors(object)"
                            />

                            <template v-else>
                                <slot
                                    name="properties"
                                    :object="object"
                                >
                                    <div class="media-library-properties media-library-properties-fixed">
                                        <div
                                            v-if="object.attributes.extension"
                                            class="media-library-property"
                                        >
                                            {{ object.attributes.extension.toUpperCase() }}
                                        </div>

                                        <div
                                            v-if="object.attributes.size"
                                            class="media-library-property"
                                        >
                                            {{ (object.attributes.size / 1024).toFixed(2) }}
                                        </div>
                                    </div>
                                </slot>

                                <slot
                                    name="fields"
                                    :object="object"
                                    :get-custom-property-input-props="(propertyName) => getCustomPropertyInputProps(object, propertyName)
                                    "
                                    :get-custom-property-input-listeners="(propertyName) => getCustomPropertyInputListeners(object, propertyName)
                                    "
                                    :get-custom-property-input-errors="(propertyName) => getCustomPropertyInputErrors(object, propertyName)
                                    "
                                    :get-name-input-props="() => getNameInputProps(object)"
                                    :get-name-input-listeners="() => getNameInputListeners(object)"
                                    :get-name-input-errors="() => getNameInputErrors(object)"
                                >
                                    <div class="media-library-properties">
                                        <div class="media-library-field">
                                            <label class="media-library-label">
                                                {{ window.mediaLibraryTranslations.name }}
                                            </label>
                                            <input
                                                class="media-library-input"
                                                :value="object.attributes.name"
                                                :disabled="!allowImageUploading"
                                                dusk="media-library-field-name"
                                                v-on="getNameInputListeners(object)"
                                            >

                                            <div
                                                v-if="object.upload"
                                                :class="`media-library-progress-wrap mt-[5px] ${
                                                    object.upload.hasFinishedUploading ? '' : 'media-library-progress-wrap-loading'
                                                }`"
                                            >
                                                <progress
                                                    max="100"
                                                    :value="object.upload.uploadProgress"
                                                    class="media-library-progress"
                                                />
                                            </div>

                                            <p
                                                v-for="error in getNameInputErrors(object)"
                                                :key="error"
                                                class="media-library-field-error"
                                            >
                                                {{ error }}
                                            </p>
                                        </div>
                                    </div>
                                </slot>
                            </template>

                            <div
                                v-if="allowImageUploading"
                                class="media-library-row-remove"
                                dusk="remove"
                                @click.stop="handleMediaRemoval(removeMedia, object)"
                            >
                                <icon icon="remove" />
                            </div>
                        </div>
                    </div>

                    <hidden-fields
                        :name="name"
                        :media-state="state.media"
                    />

                    <div
                        v-show="!maxItems || state.media.length < maxItems"
                        class="media-library-uploader"
                    >
                        <uploader
                            v-show="allowImageUploading"
                            v-bind="{ ...getDropZoneProps(), ...getFileInputProps() }"
                            add
                            multiple
                            :file-type-help-text="fileTypeHelpText"
                            v-on="{ ...getDropZoneListeners(), ...getFileInputListeners() }"
                        />
                    </div>
                </div>
            </div>
        </media-library-renderless>
        <vue-easy-lightbox
            v-if="showMediaPopup"
            esc-disabled
            :visible="showMediaPopup"
            :imgs="[
                {
                    src: getMediaURL,
                    alt: selectedMedia.name,
                    caption: selectedMedia.name
                }
            ]"
            @hide="showMediaPopup = false, selectedMedia = {}"
        />
    </div>
</template>

<script>
import Vue from 'vue'
import VueDragula from 'vue-dragula'
import {
    MediaLibraryRenderless,
    HiddenFields,
    ListErrors,
    ItemErrors,
    Icons,
    Icon,
    Uploader
} from '@spatie/media-library-pro-vue2'

import MediaLibraryThumb from './media/MediaLibraryThumb.vue'
import API from '../../api'

Vue.use(VueDragula)

export default {

    components: {
        MediaLibraryRenderless,
        HiddenFields,
        ListErrors,
        ItemErrors,
        Icons,
        Icon,
        Uploader,
        MediaLibraryThumb
    },
    props: {
        name: { required: false, type: String },
        initialValue: { required: false, type: [Array, Object] },
        validationErrors: { default: () => ({}), type: [Object, Array] },
        routePrefix: { required: false, type: String },
        translations: { default: () => { }, type: Object },
        validationRules: { required: false, type: Object },
        sortable: { default: true, type: Boolean },
        maxItems: { required: false, type: Number },
        maxSizeForPreviewInBytes: { required: false, type: Number },
        vapor: { required: false, type: Boolean },
        vaporSignedStorageUrl: { required: false, type: String },
        uploadDomain: { required: false, type: String },
        withCredentials: { required: false, type: Boolean },
        headers: { required: false, type: Object },
        fileTypeHelpText: { required: false, type: String },
        beforeUpload: { default: () => { }, type: Function },
        afterUpload: { default: () => { }, type: Function },
        allowImageUploading: { default: false, type: Boolean },
        answerId: { required: false, type: Number },
        endpoint: { required: false, type: String, default: 'form-questions-answers' }
    },

    emits: [
        'change',
        'is-ready-to-submit-change',
        'has-uploads-in-progress-change',
        'isReadyToSubmitChange',
        'hasUploadsInProgressChange'
    ],

    data: () => ({
        dragulaBagName: 'dragula-bag' + Math.random(),
        mediaLibrary: null,
        window,
        selectedMedia: {},
        showMediaPopup: false
    }),

    computed: {
        getMediaURL () {
            return this.selectedMedia?.url
        }
    },

    created () {
        Vue.vueDragula.eventBus.$on('dragend', (e) => {
            if (e[0] !== this.dragulaBagName || (!e[1] && e[1].parentElement)) {
                return
            }

            this.$refs.mediaLibraryRenderless.setOrder(
                Array.from(e[1].parentElement.children || []).map((element) => {
                    return element.getAttribute('data-media-library-uuid')
                }),
                false
            )
        })

        Vue.vueDragula.options(this.dragulaBagName, {
            moves (_el, _container, handle) {
                if (!handle) {
                    return false
                }

                return Boolean(handle.closest('.dragula-handle'))
            }
        })
    },

    mounted () {
        this.mediaLibrary = this.$refs.mediaLibraryRenderless.mediaLibrary
    },
    methods: {
        openPopup (obj) {
            this.showMediaPopup = true
            this.selectedMedia = { url: obj.attributes?.url, name: obj.attributes?.name, id: obj.attributes?.id, responsiveURL: obj.attributes?.responsive }
        },
        async handleMediaRemoval (callback, object) {
            this.$showConfirmation({
                title: 'Delete media?',
                description: 'Are you sure you want to delete this file?',
                okMethod: async () => {
                    this.$store.commit('SET_DISABLE_LOADER_TO_STATE', true)
                    const { attributes: { uuid = '' } = {} } = object
                    callback(object)
                    try {
                        await API.Models.deleteMedia(this.endpoint, this.answerId, uuid)
                    } catch (error) {
                        console.error('Error in handleMediaRemoval (MediaLibraryCollection):', error)
                    } finally {
                        this.$store.commit('SET_DISABLE_LOADER_TO_STATE', false)
                    }
                },
                cancelMethod: () => {},
                type: 'delete',
                okText: 'Ok, delete'
            })
        }
    }
}
</script>
